<template>
  <div class="pageContol">
    <!-- 列表部分 -->
    <div class="framePage">
      <!-- 页面位置 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">专家库</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 操作区 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="searchData.expertName" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em;">手机号/微信号:</span>
              <el-input v-model="searchData.expertPhoneNumber" type="text" size="small" placeholder="请输入手机号" clearable />
            </div>
            <div title="擅长领域" class="searchboxItem ci-full">
              <span class="itemLabel">擅长领域:</span>
              <el-input v-model="searchData.expertExcel" type="text" size="small" placeholder="请输入擅长领域" clearable />
            </div>
            <div title="专家类型" class="searchboxItem ci-full">
              <span class="itemLabel">专家类型:</span>
              <el-select v-model="searchData.expertType" placeholder="请选择" size="small" clearable>
                <el-option v-for="item in expertTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="备注" class="searchboxItem ci-full">
              <span class="itemLabel">备注:</span>
              <el-input v-model="searchData.remarks" type="text" size="small" placeholder="请输入备注" clearable />
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="addOrEdit()">新增</el-button>
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px" />
              <el-table-column label="姓名" align="left" prop="expertName" show-overflow-tooltip min-width="120" />
              <el-table-column label="手机号/微信号" align="left" prop="expertPhoneNumber" show-overflow-tooltip
                min-width="200" />
              <el-table-column label="专家类型" align="left" prop="expertType" show-overflow-tooltip min-width="180">
                <template slot-scope="{row}">
                  {{ $setDictionary("EXPERT_TYPE", row.expertType) }}
                </template>
              </el-table-column>
              <el-table-column label="所属单位" align="left" prop="workUnit" show-overflow-tooltip min-width="150" />
              <el-table-column label="擅长领域" align="left" prop="expertExcel" show-overflow-tooltip min-width="150" />
              <el-table-column label="个人简介" align="left" prop="expertIntroduction" show-overflow-tooltip
                min-width="150" />
              <el-table-column label="备注" align="left" prop="remarks" show-overflow-tooltip min-width="150" />
              <el-table-column label="操作" align="center" width="140px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="addOrEdit(scope.row.expertId)">编辑</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="deleteExpert(scope.row.expertId)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/expertDatabase",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data () {
    return {
      searchData: {
        expertName: "",//姓名
        expertPhoneNumber: "",//手机号
        expertType: "",//专家类型
        expertExcel: "",//擅长领域
        remarks: "",//备注
      },
      expertTypeList: [],//专家类型list
    };
  },
  created () {
    this.dataDictionary()
  },
  computed: {},
  methods: {
    // 获取列表数据
    getData (pageNum = 1) {
      const { expertName, expertPhoneNumber, expertType, expertExcel, remarks } = this.searchData
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        expertName: expertName || undefined,
        expertPhoneNumber: expertPhoneNumber || undefined,
        expertType: expertType || undefined,
        expertExcel: expertExcel || undefined,
        remarks: remarks || undefined
      };
      this.doFetch({
        url: "/biz/expert/pageList",
        params,
        pageNum,
      });
    },
    // 获取 - 数据字典
    dataDictionary () {
      // 考试科目
      const expertType = this.$setDictionary("EXPERT_TYPE", "list");
      for (const key in expertType) {
        this.expertTypeList.push({
          value: key,
          label: expertType[key],
        });
      }
    },
    addOrEdit (expertId) {
      this.$router.push({
        path: '/web/operate/expertDatabaseInfo',
        query: { expertId }
      })
    },
    deleteExpert (expertId) {
      this.doDel({
        url: "/biz/expert/delete",
        msg: "你确定要删除该专家吗？",
        ps: {
          type: "post",
          data: { expertId },
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (from.path == '/web/operate/expertDatabaseInfo') {
        vm.getData(vm.pageNum)
      }
    })
  }
};
</script>
<style>
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
<style lang="less" scoped>
.el-textarea__inner {
  resize: none;
}
</style>
